body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat;
}

:where(.css-dev-only-do-not-override-1me4733).ant-select-dropdown
  .ant-select-item {
  font-family: Montserrat;
  font-size: 16px;
  color: #33475b !important;
}

:where(.css-dev-only-do-not-override-1me4733).ant-input {
  font-family: Montserrat;
  font-size: 16px;
  color: #7c98b6 !important;
}
