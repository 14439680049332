.wrapper-content {
  padding: 30px 160px;
  background-color: #f5f8fa;
  margin-top: 160px;
}

.wrapper-services-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 480px) {
  .wrapper-content {
    padding: 30px;
  }

  .wrapper-services-content {
    flex-direction: column;
  }

  .wrapper-services {
    width: "100%";
  }

  .wrapper-pages {
    width: "100%";
  }
}
